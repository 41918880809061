import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from 'vuetify/lib/framework';
import VueRouter from 'vue-router';
import VCalendar from 'v-calendar';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VCalendar, {componentPrefix: 'vc'});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        //primary: colors.blue.darken3, // #E53935
        primary: '#2c3e50',
        secondary: colors.blue.lighten4, // #FFCDD2
        accent: colors.blue.lighten3, // #3F51B5
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
