import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userName: '',
    groupName: '',
    isLoggedIn: false
  },
  mutations: {
    updateLoginInfo(state, { userName, groupName, isLoggedIn }) {
      state.userName = userName;
      state.groupName = groupName;
      state.isLoggedIn = isLoggedIn;
    }
  }
});
export default store;