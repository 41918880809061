<template>
  <v-app>
    <Header v-if="isHeaderVisible()" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer v-if="isFooterVisible()" />
    <notifications group="foo" position="center" />
  </v-app>
</template>

<script>
import Header from './components/Base/Header.vue';
import Footer from './components/Base/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer, Header
  },
  methods: {
    isHeaderVisible() {
      return !['/edge-eye-grid'].includes(this.$router.currentRoute.path);
    },
    isFooterVisible() {
      return this.$vuetify.breakpoint.mdAndUp 
        && !['/edge-eye-grid'].includes(this.$router.currentRoute.path);
    }
  },
  data: () => ({
  }),
};
</script>

<style>
.body-color {
  color: white;
  background-color: var(--v-primary-base);
}
@font-face {
    font-family: "NanumSquareRoundB";
    src: local("NanumSquareRoundB"),
     url(./assets/fonts/NanumFontSetup_TTF_SQUARE_ROUND/NanumSquareRoundB.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquareRoundEB";
  src: local("NanumSquareRoundEB"),
    url(./assets/fonts/NanumFontSetup_TTF_SQUARE_ROUND/NanumSquareRoundEB.ttf) format("truetype");
}
.bold-font {
    font-family: "NanumSquareRoundEB";
}
</style>
