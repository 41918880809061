<template>
  <div class="pl-2" style="">
    <div v-for="(edgeEye, index) in edgeEyeData" :key="index">
      <EdgeEyeViewer :edgeEye="edgeEye"/>
    </div>
    <div class="d-flex justify-center align-center empty-msg" v-if="edgeEyeData.length == 0">
      <div style="color:#818181">EdgeEye 장치가 없습니다.</div>
    </div>
  </div>
  <!-- <v-img src="https://picsum.photos/id/11/500/300"></v-img> -->
</template>
<script>
import EdgeEyeViewer from './EdgeEyeViewer.vue';

export default {
  name: 'EdgeEyePanel',
  props: {
    edgeEyeData: Array
  },
  components: {
    EdgeEyeViewer
  },
  data() {
    return {
    };
  }
}
</script>
<style scoped>
.empty-msg {
  height:calc(100vh - 250px);
  border-left: 1px solid #E5E5E5;
}
</style>