<template>
  <div style="height:100%;">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ko } from 'date-fns/locale';

Chart.register(...registerables);
Chart.defaults.font.family = 'NanumSquareRoundB';

export default {
  name: 'BattChart',
  props: {
    chartId: String,
    title: String,
    dataArr: Array,
    titleColor: String,
    borderColor: String,
    fillColor: String,
    yAxisMin: Number,
    yAxisMax: Number,
    yUnit: String
  },
  mounted() {
    const ctx = document.getElementById(this.chartId).getContext('2d');
    this.yMax = this.yAxisMax;
    this.yMin = this.yAxisMin;
    this.chartObj = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: this.title,
          data: this.dataArr,
          fill: {
            target: 'origin',
            above: this.fillColor
          },
          borderColor: this.borderColor
        }]
      },
      options: {
        scales: {
          y: {
            min: this.yMin, max:  this.yMax,
          },
          x: {type: "time", ticks: {source: 'auto', autoSkip: true, maxTicksLimit: 7},
            time: {minUnit: 'hour', displayFormats: {day: 'M/d', hour: 'M/d HH:MM'}},
            adapters: {date: {locale: ko}},
          },
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            align: 'center',
            display: true,
            position: 'top',
            text: this.title,
            font: {
              weight: 'bold',
              size: this.titleFontSize(),
              lineHeight: 1.0,
              family: 'NanumSquareRoundB'
            }
          },
          tooltip: {
            titleFont: {
              size: 14
            },
            bodyFont: {
              size: 14
            }
          }
        },
        elements: {
          point: {
            radius: 0
          },
          line: {
            tension: 0.2,
            borderWidth: 2
          }
        },
        animation: false,
        maintainAspectRatio: false,
        responsive: true
      }
    });
  },
  methods: {
    titleFontSize() {
      return '12em';
    },
    pointSize() {
      return 1;
    },
  },
  data() {
    return {
      chartObj: null,
      yMax: 100,
      yMin: 0,
    }
  }
}
</script>

<style scoped>
</style>