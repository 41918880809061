<template>
  <div class="d-flex justify-center align-center page_not_found" style="height:100%;">
    <v-icon class="ma-2" large>mdi-alert</v-icon>
    <span>Page Not Found</span>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style scoped>
.page_not_found {
  color: var(--v-primary-base);
  font-size: xx-large;
}
</style>