import axios from 'axios'
import VueRouter from 'vue-router';
import store from '../store/index';
import Login from '../components/Login.vue';
import Dashboard from '../components/Dashboard.vue';
import AdminView from '../components/AdminView.vue';
import EdgeEyeGrid from '../components/EdgeEyeGrid.vue';
import SearchLineChart from '../components/SearchLineChart.vue';
import SearchDataTable from '../components/SearchDataTable.vue';
import PageNotFound from '../components/PageNotFound.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(error => {
    if(error.name != 'NavigationDuplicated') {
      throw error;
    }
  });
};  

async function isLoggedIn() {
  try {
    const res = await axios({method: 'get', url: '/api/isLoggedIn', withCredentials: true});
    store.commit('updateLoginInfo', {userName: store.state.userName, groupName: res.data.groupName, isLoggedIn: true});
    return true;
  } catch (error) {
    store.commit('updateLoginInfo', {userName: "", groupName: "", isLoggedIn: false});
    return false;
  }
}

const routes = [
  { path: '/login', component: Login },
  { path: '/dashboard', component: Dashboard },
  { path: '/search-chart', component: SearchLineChart },
  { path: '/search-table', component: SearchDataTable },
  { path: '/admin', component: AdminView },
  { path: '/edge-eye-grid', component: EdgeEyeGrid },
  { path: '/:pathMatch(.*)*', component: PageNotFound}
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  if(await isLoggedIn() == false) {
    if(to.path != '/login') {
      next({path: '/login'});
    } else {
      next();
    }
  } else {
    if((to.path == '/admin' || to.path == '/') && store.state.groupName != 'admin') {
      next({path: '/dashboard'});
    } else if((to.path == '/') && store.state.groupName == 'admin') {
      next({path: '/admin'});
    } else {
      next();
    }
  }
});

export default router;