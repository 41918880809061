exports.devSort = function(devArr) {
  let sorted = new Array(devArr.length);
  let minVal = Number.MAX_VALUE;
  let minIdx = 0;
  for(let i=0; i<sorted.length; i++) {
    minIdx = 0;
    minVal = Number.MAX_VALUE;
    for(let j=0; j<devArr.length; j++) {
      let postfix = parseInt(devArr[j].devType.split('-')[1]);
      if(minVal > postfix) {
        minVal = postfix;
        minIdx = j;
      }
    }
    sorted[i] = devArr[minIdx];
    devArr.splice(minIdx, 1);
  }
  return sorted;
}