<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <template v-slot:activator="{ attrs }">
      <v-btn class="success" v-bind="attrs" block depressed @click.stop="dialog = true" :disabled="disabled">
        <v-icon class="mr-3">mdi-download</v-icon>CSV 저장
      </v-btn>
    </template>
    <v-sheet class="px-3 py-2">
      <v-list>
        <v-list-item two-line>
          <v-list-item-content style="border-left: 6px solid #2c3e50;padding-left:10px;margin-bottom:5px;">
            <v-list-item-title>CSV 다운로드 ({{ selectedGroup != null ? selectedGroup.groupName : '' }})</v-list-item-title>
            <v-list-item-subtitle>검색 날짜 범위는 최대 1년까지 가능합니다.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-4"></v-divider>
        <v-list-item>
          <v-row class="my-3" no-gutters>
            <v-col cols="5">
              <vc-date-picker v-model="fromTime" mode="dateTime" :max-date='new Date()' 
                is-required is24hr :popover="{ positionFixed: true }">
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field ref="fromTextField" label="From" prepend-icon="mdi-calendar-month" 
                    :value="inputValue" v-on="inputEvents" :error-messages="fromRangeErr" :rules="[checkFromRange]"
                    readonly></v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="1"><div class="d-flex justify-center pt-6"><v-icon small>mdi-tilde</v-icon></div></v-col>
            <v-col cols="5">
              <vc-date-picker v-model="toTime" mode="dateTime" :max-date='new Date()' 
                is-required is24hr :popover="{positionFixed: true}">
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field ref="toTextField" label="To" prepend-icon="mdi-calendar-month" 
                    :value="inputValue" v-on="inputEvents" :error-messages="toRangeErr"  :rules="[checkToRange]"
                    readonly></v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <div class="d-flex justify-end align-center" style="width:100%;">
            <v-checkbox
              v-model="withImage" style="margin:0px;" hide-details
              label="이미지 포함"
            ></v-checkbox>
          </div>
        </v-list-item>
        <v-list-item>
          <v-alert :type="resultType" dense v-if="resultMessage != ''" style="width:100%">
            {{ resultMessage }}
          </v-alert>
        </v-list-item>
        <v-list-item>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="3" class="pr-3">
              <v-btn class="" depressed block @click="requestClose" :disabled="loading">닫기</v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn color="primary" depressed block  :disabled="fromRangeErr != '' || toRangeErr != ''"
                :loading="loading" @click="csvDownload">다운로드</v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-dialog>
</template>

<script>
import moment from 'moment';

export default {
  name: "CSVExportDialog",
  props: {
    selectedGroup: Object,
    loading: Boolean,
    resultMessage: String,
    resultType: String,
    disabled: Boolean
  },
  watch: {
    loading(val) {
      this.loader = !val;
    }
  },
  mounted() {
  },
  methods: {
    checkFromRange() {
      if(this.fromTime != null && this.toTime != null) {
        if(this.toTime.getTime() < this.fromTime.getTime()) {
          this.fromRangeErr = "시간 조건을 재확인해주세요";
          return false;
        }

        let limitTime = new Date(this.toTime);
        limitTime = moment(limitTime).subtract(366, 'day').toDate();
        if(limitTime.getTime() > this.fromTime.getTime()) {
          this.fromRangeErr = "검색 범위는 1년까지 입니다.";
          return false;
        }

        this.fromRangeErr = "";
        if(this.toRangeErr != "") {
          this.$refs.toTextField.validate(true);
        }
      }
      return true;
    },
    checkToRange() {
      if(this.fromTime != null && this.toTime != null) {
        if(this.toTime.getTime() < this.fromTime.getTime()) {
          this.toRangeErr = "시간 조건을 재확인해주세요";
          return false;
        }

        let limitTime = new Date(this.fromTime);
        limitTime = moment(limitTime).add(366, 'day').toDate();
        if(limitTime.getTime() < this.toTime.getTime()) {
          this.toRangeErr = "검색 범위는 1년까지 입니다.";
          return false;
        }

        this.toRangeErr = "";
        if(this.fromRangeErr != "") {
          this.$refs.fromTextField.validate(true);
        }
      }
      return true;
    },
    csvDownload() {
      this.$emit('csvDownload', this.fromTime, this.toTime, this.withImage);
    },
    requestClose() {
      this.dialog = false;
      this.$emit('clear');
    }
  },
  data () {
    let toTime = new Date();
    toTime.setHours(23, 59);
    let fromTime = new Date();
    fromTime.setDate(toTime.getDate() - 7);
    fromTime.setHours(0);
    fromTime.setMinutes(0);
    return {
      dialog: false,
      loader: false,
      fromRangeErr: "", toRangeErr: "",
      fromTime: fromTime, toTime: toTime,
      withImage: false
    }
  },
}
</script>