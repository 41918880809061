<template>
  <div style="height:100%;">
    <v-btn v-if="fixedMax != true" class="chart_y_axis_setting_button" x-small rounded depressed @click="openEditYAxisDlg">
      Y축 설정
      <v-icon x-small>
        mdi-pencil
      </v-icon>
    </v-btn>
    <div class="chart_y_axis_degree">
      {{ yAxisName }}
    </div>
    <div style="height:85%;width:100%;">
      <canvas :id="chartId"></canvas>
    </div>
    <div class="chart_timeline" style="height:15%;width:100%;">
      <canvas :id="subChartId" class="chart_timeline_canvas"></canvas>
      <div :id="`${chartId}-left`" class="chart_timeline_rest_left" style="width: 0%;"></div>
      <div :id="`${chartId}-center`" class="chart_timeline_handle" style="left: 0%; right: 0%;">
        <div class="chart_timeline_handle_touch_area"></div>
      </div>
      <div :id="`${chartId}-right`" class="chart_timeline_rest_right" style="width: 0%;"></div>
    </div>
  
    <v-dialog v-model="dlgEditYMax" width="600" :retain-focus="false">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-sheet class="px-3 py-2">
          <v-list>
            <v-list-item two-line>
              <v-list-item-content style="border-left: 6px solid #2c3e50;padding-left:10px;margin-bottom:5px;">
                <v-list-item-title>Y축 최대/최소값 설정</v-list-item-title>
                <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
            <v-list-item>
              <v-row class="ma-2">
                <v-col cols="6">
                  <v-text-field v-model="yMaxInput" type="number" label="Y축 최대값" :suffix="yAxisName" :rules="yMaxRules"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="yMinInput" type="number" label="Y축 최소값" :suffix="yAxisName" :rules="yMinRules"></v-text-field>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="3" class="pr-3">
                  <v-btn class="" Depressed block @click="closeEditYAxisDlg" elevation="0">취소</v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn color="primary" :disabled="!formValid" Depressed block @click="applyYAxis" elevation="0">저장</v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {ko} from 'date-fns/locale';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as Hammer from 'hammerjs';

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.defaults.font.family = 'NanumSquareRoundB';

export default {
  name: 'ZoomChart',
  props: {
    chartId: String,
    title: String,
    dataArr: Array,
    yAxisName: String,
    borderColor: String,
    maxValue: Number,
    subChartId: String,
    fixedMax: Boolean
  },
  mounted() {
    const ctx = document.getElementById(this.chartId).getContext('2d'); 
    const subCtx = document.getElementById(this.subChartId).getContext('2d');
    let tl_center = document.getElementById(`${this.chartId}-center`);
    let tl_left = document.getElementById(`${this.chartId}-left`);
    let tl_right = document.getElementById(`${this.chartId}-right`);
    
    let max = this.dataArr.length > 0 ? new Date(this.dataArr[0].x).getTime() : 0;
    let min = this.dataArr.length > 0 ? new Date(this.dataArr[this.dataArr.length-1].x).getTime() : 0;

    if(this.fixedMax) {
      this.yMax = this.maxValue;
    } else {
      this.yMax = this.dataArr.length > 0 ? Math.ceil(this.maxValue * 1.5) : 100;
    }
    this.yMin = 0;

    let scaleOptions = {
      x: {type: "time", ticks: {source: 'auto', autoSkip: true, maxTicksLimit: 7},
        time: {minUnit: 'hour', displayFormats: {day: 'y/M/d', hour: 'M/d HH:MM'}},
        adapters: {date: {locale: ko}},},
      y: {min: this.yMin, max: this.yMax},
    };
    let elementOptions = {
      point: {pointStyle: true, radius: 2, borderWidth: 0, backgroundColor: 'rgba(255,255,255,0)', hitRadius: 5},
      line: {tension: 0.2, borderWidth: 2}
    };
    let zoomOptions = {
      pan: {enabled: true, mode: 'x', 
        onPan: ({chart}) => { this.handleEvent(chart, min, max, tl_left, tl_center, tl_right); }},
      zoom: {wheel: {enabled: true}, mode: 'x',
        onZoom: ({chart}) => { this.handleEvent(chart, min, max, tl_left, tl_center, tl_right); }},
      limits: {x: {min: 'original', max: 'original'}},
    };
    let pluginOptions = {
      legend: {display: false},
      zoom: zoomOptions,
      title: {align: 'center', display: true, position: 'top', text: this.dataArr.length > 0 ? this.title : `${this.title}(데이타 없음)`,
        font: {weight: 'bold', size: this.titleFontSize(), lineHeight: 2.0, family: 'NanumSquareRoundB'}},
      tooltip: {titleFont: {size: 20}, bodyFont: {size: 20}, yAlign: "bottom"}
    }

    let scaleOptionsSub = {
      x: {display: false, type: "time", ticks: {source: 'auto'}},
      y: {display: false, min: this.yMin,max:  this.yMax}
    };
    let pluginOptionsSub = {legend: {display: false}}

    this.chartObj = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{label: this.title, data: this.dataArr, borderColor: this.borderColor}]
      },
      options: {
        scales: scaleOptions, plugins: pluginOptions, elements: elementOptions,
        animation: false, maintainAspectRatio: false, responsive: true
      },
    });

    this.subChartObj = new Chart(subCtx, {
      type: 'line',
      data: {
        datasets: [{label: this.title, data: this.dataArr, borderColor: this.borderColor}]
      },
      options: {
        scales: scaleOptionsSub, plugins: pluginOptionsSub, elements: elementOptions,
        animation: false, maintainAspectRatio: false, responsive: true
      }
    });

    this.initMinimapEventHandler(this.chartObj, tl_center);
  },
  methods: {
    handleEvent(chart, min, max, tl_left, tl_center, tl_right) {
      let curMin = chart.scales.x.min;
      let curMax = chart.scales.x.max;
      let curLeft = ((curMin - min) / (max - min)) * 100;
      let curWidth = ((curMax - curMin) / (max - min)) * 100;
      tl_left.style.width = `${curLeft}%`;
      tl_center.style.left = `${curLeft}%`;
      tl_center.style.width = `${curWidth}%`;
      tl_right.style.width = `${100 - (curLeft + curWidth)}%`;
    },
    initMinimapEventHandler(chart, tl_center) {
      let mc = new Hammer.Manager(tl_center);
      const threshold = 10;
      mc.add(new Hammer.Pinch());
      mc.add(new Hammer.Pan({ threshold: 10 }));

      let currentDeltaX = 0;
      let currentDeltaY = 0;
      const handlePan = (e) => {
        const deltaX = e.deltaX - currentDeltaX;
        currentDeltaX = e.deltaX;
        const perc = parseFloat(tl_center.style.width) / 100 || 0.1;
        chart.pan({x: -deltaX / perc, y: 0});
      };
      mc.on('panstart', function(e) {
        currentDeltaX = 0;
        currentDeltaY = 0;
        handlePan(e);
      });
      mc.on('panmove', handlePan);
    },
    openEditYAxisDlg() {
      this.yMaxInput = `${this.yMax}`;
      this.yMinInput = `${this.yMin}`;
      this.dlgEditYMax = true;
    },
    applyYAxis() {
      this.yMax = this.yMaxInput;
      this.yMin = this.yMinInput;
      this.chartObj.options.scales.y.max = Math.floor(this.yMax);
      this.chartObj.options.scales.y.min = Math.floor(this.yMin);
      this.subChartObj.options.scales.y.max = Math.floor(this.yMax);
      this.subChartObj.options.scales.y.min = Math.floor(this.yMin);
      this.chartObj.update('none');
      this.subChartObj.update('none');
      this.dlgEditYMax = false;
    },
    closeEditYAxisDlg() {
      this.dlgEditYMax = false;
    },
    titleFontSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '20em' : '12em';
    },
    pointSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 2 : 1;
    },
  },
  data() {
    return {
      chartObj: null,
      subChartObj: null,
      dlgEditYMax: false,
      yMaxInput: '100',
      yMinInput: '0',
      yMax: 100,
      yMin: 0,
      formValid: true,
      yMaxRules: [
        v => !!v || '최대값을 입력해주세요',
        v => !isNaN(v) || '잘못된 값입니다',
        v => v > this.yMinInput || '최소값보다 커야 합니다'
      ],
      yMinRules: [
        v => !!v || '최소값을 입력해주세요',
        v => !isNaN(v) || '잘못된 값입니다',
        v => v < this.yMaxInput || '최대값보다 작아야 합니다'
      ],
    }
  }
}
</script>

<style scoped>
.chart_timeline {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.chart_timeline_handle {
  cursor: ew-resize;
  z-index: 1;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: 0;
  border: #C0D1E1 solid;
  border-width: 1px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px #fff, inset 1px 0 0 0 #fff, inset -1px 0 0 0 #fff;
/*   cursor: pointer; */
  touch-action: pan-x;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.chart_timeline_canvas {
  height: 40px;
  border-radius: 5px;
}
.chart_timeline_handle::before,
.chart_timeline_handle::after {
  content: '';
  position: absolute;
  left: -6px;
  top: 15px;
  bottom: 15px;
  width: 2px;
  background: #fff;
  border-radius: 2px;
}
.chart_timeline_rest_left,
.chart_timeline_rest_right {
  position: absolute;
  top: 0;
  background: rgba(226, 238, 249, 0.5);
  height: 100%;
  padding: 0 5px;
  border-radius: 5px;
}
.chart_timeline_handle:after {
  left: auto;
  right: -6px;
}
.chart_timeline_rest_left {
  left: 0;
}
.chart_timeline_rest_right {
  right: 0;
}
.chart_y_axis_setting_button {
  position:absolute;
  margin-left:-12px;
  margin-top:10px;
}
.chart_y_axis_degree {
  position:absolute;
  margin-left:2px;
  margin-top:36px;
  font-size:12px;
  color: grey;
}
:deep(.v-text-field__suffix){
  font-size: 12px;
}
</style>