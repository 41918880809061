<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-dialog v-model="dialog" width="700" :retain-focus="false" persistent>
      <v-sheet class="px-5 py-2">
        <v-list>
          <v-list-item two-line>
            <v-list-item-content style="border-left: 6px solid #2c3e50;padding-left:10px;margin-bottom:5px;">
              <v-list-item-title>유량계 수식 설정 <v-chip class="ml-2">{{groupName}}</v-chip></v-list-item-title>
              <v-list-item-subtitle>ID: {{devId}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ devDesc }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
          <v-list-item>
            <v-row class="my-2">
              <v-col cols="4">
                <v-select v-model="selectedFlowMeterType" dense :items="flowMeterTypes" v-on:change="changeFlowMeterType"
                  item-text="name" item-value="type" label="유량계 타입" :disabled="disable" hide-details></v-select>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <TriangularWeir ref="refTriangularWeir" v-if="selectedFlowMeterType.type == 'triangular'" 
              :ppParam="ppParam"></TriangularWeir>
            <RectangularWeir ref="refRectangularWeir" v-if="selectedFlowMeterType.type == 'rectangular'" 
              :ppParam="ppParam"></RectangularWeir>
            <ParshallFlume ref="refParshallFlume"  v-if="selectedFlowMeterType.type == 'parshall'" 
              :ppParam="ppParam"></ParshallFlume>
            <div v-if="!isValidPp" style="width:100%;">
              <v-alert outlined type="error">
                IOTOWN에서 장치의 Post Processor와 파라메터를 올바르게 설정해주세요.
              </v-alert>
            </div>
          </v-list-item>
          <v-divider class="mx-4 mb-3"></v-divider>
          <v-list-item>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="3" class="pr-3">
                <v-btn class="" Depressed block elevation="0" @click="closeDialog">취소</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" :disabled="!formValid || disable" :loading="closeLoading" 
                Depressed block elevation="0" @click="saveSetting">저장</v-btn>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-sheet>
      <v-overlay v-if="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-form>
</template>

<script>
import axios from 'axios';
import TriangularWeir from './TriangularWeir.vue';
import RectangularWeir from './RectangularWeir.vue';
import ParshallFlume from './ParshallFlume.vue';

export default {
  name: 'FlowMeterSettingDialog',
  components: {
    TriangularWeir,
    RectangularWeir,
    ParshallFlume
  },
  props: {
    dlg: Boolean,
    groupId: String,
    groupName: String,
    devId: String,
    devDesc: String
  },
  watch: {
    dlg() {
      this.dialog = this.dlg;
      if(this.dlg) {
        this.initDialog();
      }
    }
  },
  mounted() {
  },
  methods: {
    async initDialog() {
      this.disable = false;
      this.dialog = true;
      this.loading = true;
      this.sensors = [];
      this.curTitle = this.groupName;
      let ret = await axios({ method: 'get', url: `/api/device/${this.groupId}/${this.devId}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let device = ret.data.device;
      let _isValidPp = false;
      if(device != null || device.pp_list.length > 0) {
        for(let i=0; i<device.pp_list.length; i++) {
          if(device.pp_list[i].name == 'iotown-proc:common:KangwonCorp') {
            try{
              let ppParam = JSON.parse(`{${device.pp_list[i].param}}`);
              this.selectedFlowMeterType = {type: ppParam.type, name: this.getTypeName(ppParam.type)};
              this.ppParam = ppParam;
              if(ppParam.type == 'triangular' || ppParam.type == 'rectangular' || ppParam.type == 'parshall') {
                _isValidPp = true;
              }
            } catch(e) {
              console.log(e);
            }
          }
        }
      }
      this.ppList = device.pp_list;
      this.loading = false;
      this.isValidPp = _isValidPp;
      if(!_isValidPp) {
        this.disable = true;
      }
    },
    async changeFlowMeterType(selectedType) {
      this.selectedFlowMeterType = {type: selectedType, name: this.getTypeName(selectedType)};
    },
    async saveSetting() {
      if(!this.$refs.form.validate()) {
        return;
      }
      
      let curType = this.selectedFlowMeterType.type;
      let paramStr = "";
      if(curType == 'triangular') {
        let var_B = this.$refs.refTriangularWeir.var_B;
        let var_D = this.$refs.refTriangularWeir.var_D;
        let var_dist_from_ground = this.$refs.refTriangularWeir.var_dist_from_ground;
        paramStr = `"type":"triangular","B":"${var_B}","D":"${var_D}","dist_from_ground":${var_dist_from_ground}`;
      } else if(curType == 'rectangular') {
        let var_b = this.$refs.refRectangularWeir.var_b;
        let var_B = this.$refs.refRectangularWeir.var_B;
        let var_D = this.$refs.refRectangularWeir.var_D;
        let var_dist_from_ground = this.$refs.refRectangularWeir.var_dist_from_ground;
        paramStr = `"type":"rectangular","b":"${var_b}","B":"${var_B}","D":"${var_D}","dist_from_ground":${var_dist_from_ground}`;
      } else if(curType == 'parshall') {
        let var_C = this.$refs.refParshallFlume.var_C;
        let var_dist_from_ground = this.$refs.refParshallFlume.var_dist_from_ground;
        paramStr = `"type":"parshall","C":"${var_C}","dist_from_ground":${var_dist_from_ground}`;
      }

      let pp_list = this.ppList;
      for(let i=0; i<pp_list.length; i++) {
        if(pp_list[i].name == 'iotown-proc:common:KangwonCorp') {
          pp_list[i].param = paramStr;
          break;
        }
      }

      let jObj = JSON.parse(JSON.stringify(pp_list));
      try {
        this.closeLoading = true;
        await axios({
          method: 'put', url: `/api/device/${this.groupId}/${this.devId}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          data: { pp_list: jObj }
        });
        setTimeout(() => {
          this.closeLoading = false;
          this.closeDialog();
        }, 1000);
      } catch(e) {
        console.log(e);
      }
    },
    async closeDialog() {
      this.dialog = false;
      this.selectedFlowMeterType = {};
      this.$emit('close');
    },
    getTypeName(type) {
      for(let i=0; i<this.flowMeterTypes.length; i++) {
        if(this.flowMeterTypes[i].type == type) {
          return this.flowMeterTypes[i].name;
        }
      }
    },
  },
  data() {
    return {
      closeLoading: false,
      loading: false,
      dialog: false,
      selectedFlowMeterType: {},
      flowMeterTypes: [
        {type: 'triangular', name: "삼각위어"},
        {type: 'rectangular', name: "사각위어"},
        {type: 'parshall', name: "파샬플룸"}
      ],
      formValid: true,
      ppList: {},
      ppParam: {},
      isValidPp: true,
      disable: false,
    }
  },
}
</script>