<template>
  <v-container style="height:calc(100% - 70px);min-width:1024px;min-height:768px;max-width:100%;width:95%;">
    <v-row>
      <v-col cols="12" class="py-2" style="padding-left:0px;">
        <v-form>
          <div class="d-flex align-center">
            <div class="mr-auto" v-if="this.$store.state.groupName != 'admin'">
              <h2 color="primary" large label outlined><v-icon large color="primary">mdi-map-marker-radius</v-icon>{{ this.$store.state.groupName }}</h2>
            </div>
            <div class="mr-auto" v-if="this.$store.state.groupName == 'admin'">
              <v-autocomplete v-model="selectedGroup" :items="groups" label="위치 선택" 
                item-text="groupName" item-value="groupId" return-object></v-autocomplete>
            </div>
            <div class="mx-3" style="width:260px;">
              <vc-date-picker v-model="fromTime" mode="dateTime" :max-date='new Date()' is-required is24hr>
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field ref="fromTextField" label="From" prepend-icon="mdi-calendar-month" 
                    :value="inputValue" v-on="inputEvents" 
                    :error-messages="fromRangeErr" :rules="[checkFromRange]" readonly></v-text-field>
                </template>
              </vc-date-picker>
            </div>
            <div><v-icon small>mdi-tilde</v-icon></div>
            <div class="mx-3" style="width:260px;">
              <vc-date-picker v-model="toTime" mode="dateTime" :max-date='new Date()' is-required is24hr>
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field ref="toTextField" label="To" prepend-icon="mdi-calendar-month" 
                    :value="inputValue" v-on="inputEvents" 
                    :error-messages="toRangeErr"  :rules="[checkToRange]" readonly></v-text-field>
                </template>
              </vc-date-picker>
            </div>
            <div class="pb-2 ml-4" style="width:150px;">
              <v-btn block color="primary" elevation="0" @click="doSearch" :disabled="fromRangeErr != '' || toRangeErr != '' || selectedGroup == null">
                <v-icon dark>mdi-magnify</v-icon>검색
              </v-btn>
            </div>
            <div class="pb-2 ml-4 mr-0" style="width:150px;">
              <CSVExportDialog :selectedGroup="selectedGroup" v-on:csvDownload="csvDownload" v-on:clear="csvExportErr = csvExportSuccess = ''"
                :loading="csvExportLoading" :resultMessage="csvExportErr != '' ? csvExportErr : csvExportSuccess" 
                :resultType="csvExportErr != '' ? 'error' : 'success'" :disabled="selectedGroup == null" />
            </div>
          </div>
        </v-form>
      </v-col>
      <v-col cols="12" class="pa-2">
        <v-row class="d-flex align-start" v-if="chartList.length > 0">
          <v-col cols="6" v-for="(chart, index) in chartList" :key="index"
             style="height:calc(100vh / 2 - 100px);">
            <ZoomChart v-if="chart.multiple != true" :key="redrawKey" :chartId="chart.chartId" :title='chart.title' :dataArr="chart.data" :yAxisName="chart.yAxisName"
              :maxValue="chart.maxValue" :borderColor="chart.borderColor" :subChartId="chart.subChartId" :fixedMax="chart.fixedMax"></ZoomChart>
            <ZoomMultiChart v-if="chart.multiple == true" :key="redrawKey" :chartId="chart.chartId" :title='chart.title' :dataArr="chart.data" :yAxisName="chart.yAxisName"
              :maxValue="chart.maxValue" :borderColor="chart.borderColor" :subChartId="chart.subChartId" :fixedMax="chart.fixedMax"></ZoomMultiChart>
          </v-col>
        </v-row>
        <v-row v-if="chartList.length == 0" style="height:calc(100vh - 200px);">
          <v-col class="d-flex justify-center align-center text-h4 grey--text text--lighten-1">
              {{ emtpyHint }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from 'axios';
import exportFromJSON from 'export-from-json';
import CSVExportDialog from './SearchLineChart/CSVExportDialog.vue';
import ZoomChart from './SearchLineChart/ZoomChart.vue';
import ZoomMultiChart from './SearchLineChart/ZoomMultiChart.vue';
import Utils from '../js/utils';
import moment from 'moment';

Date.prototype.YYYYMMDDhhmm = function() {
  var MM = this.getMonth() + 1; // getMonth() is zero-based
  var DD = this.getDate();
  var hh = this.getHours();
  var mm = this.getMinutes();

  return [this.getFullYear(),
          (MM>9 ? '' : '0') + MM,
          (DD>9 ? '' : '0') + DD,
          (hh>9 ? '' : '0') + hh,
          (mm>9 ? '' : '0') + mm,
         ].join('');
};

export default {
  watch: {
  },
  name: 'SearchLineChart',
  components: {
    CSVExportDialog,
    ZoomChart,
    ZoomMultiChart
  },
  mounted() {
    this.loadGroupList();
  },
  methods: {
    forceRerender() {
      this.redrawKey += 1;
    },
    async loadGroupList() {
      if(this.$store.state.groupName == 'admin') {
        try {
          let ret = await axios({ method: 'get', url: '/api/groups',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          });
          let groups = ret.data.groups;
          for(let i=0; i<groups.length; i++) {
            this.groups.push({groupName: groups[i].groupName, groupId: groups[i].groupId});
            if(i==0) {
              this.selectedGroup = {groupName: groups[i].groupName, groupId: groups[i].groupId};
            }
          }
        } catch(e) {
          console.log(e);
        }
      }
    },
    checkFromRange() {
      if(this.fromTime != null && this.toTime != null) {
        if(this.toTime.getTime() < this.fromTime.getTime()) {
          this.fromRangeErr = "시간 조건을 재확인해주세요";
          return false;
        }

        let limitTime = new Date(this.toTime);
        limitTime = moment(limitTime).subtract(31*3-1, 'day').toDate();
        if(limitTime.getTime() > this.fromTime.getTime()) {
          this.fromRangeErr = "검색 범위는 3개월까지 입니다.";
          return false;
        }

        this.fromRangeErr = "";
        if(this.toRangeErr != "") {
          this.$refs.toTextField.validate(true);
        }
      }
      return true;
    },
    checkToRange() {
      if(this.fromTime != null && this.toTime != null) {
        if(this.toTime.getTime() < this.fromTime.getTime()) {
          this.toRangeErr = "시간 조건을 재확인해주세요";
          return false;
        }

        let limitTime = new Date(this.fromTime);
        limitTime = moment(limitTime).add(31*3-1, 'day').toDate();
        if(limitTime.getTime() < this.toTime.getTime()) {
          this.toRangeErr = "검색 범위는 3개월까지 입니다.";
          return false;
        }

        this.toRangeErr = "";
        if(this.fromRangeErr != "") {
          this.$refs.fromTextField.validate(true);
        }
      }
      return true;
    },
    async getDevices() {
      let grpId = '', stn = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.selectedGroup.groupId}`;
      }
      let ret = await axios({ method: 'get', url: '/api/devices' + grpId,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let devices = ret.data.devices;
      let rainDev = null;
      let flowDev = null;
      let solarDev = null;
      if(devices != null || devices.length > 0) {
        let sensors = [];
        for(let i=0; i<devices.length; i++) {
          if(devices[i].devType == '강우량계') {
            rainDev = devices[i];
            stn = JSON.parse(`{${devices[i].devDesc}}`).stn;
          } else if(/^유량계-.*/.test(devices[i].devType)) {
            sensors.push({
              devId: devices[i].devId, 
              devType: devices[i].devType,
              devDesc: devices[i].devDesc
            });
            if(stn == null || stn == undefined || stn == '') {
              stn = JSON.parse(`{${devices[i].devDesc}}`).stn;
            }
          } else if(devices[i].devType == 'CSD3') {
            solarDev = devices[i];
          }
        }
        flowDev = Utils.devSort(sensors);
      }
      return {solarDev: solarDev, rainDev: rainDev, flowDev: flowDev, stn: stn};
    },
    async getChartData(nid, to, from, tag) {
      let grpId = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.selectedGroup.groupId}`;
      }
      let queryStr = `nid=${nid}&from=${from}&to=${to}`;
      let ret = await axios({ method: 'get', url: `/api/data${grpId}?${queryStr}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let data = ret.data.data;
      let maxValue = 0;
      let chartData = [];
      for(let i=0; i<data.length; i++) {
        chartData.push({x: new Date(data[i].timestamp), y: data[i].value[tag]});
        if(maxValue < data[i].value[tag]) {
          maxValue = data[i].value[tag];
        }
      }
      return { chartData: chartData, maxValue: maxValue };
    },
    async getChartMultiData(nid, to, from, ...tags) {
      let grpId = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.selectedGroup.groupId}`;
      }
      let queryStr = `nid=${nid}&from=${from}&to=${to}`;
      let ret = await axios({ method: 'get', url: `/api/data${grpId}?${queryStr}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let data = ret.data.data;
      let maxValue = 0;
      let chartDataArr = [];
      for(let i=0; i<tags.length; i++) {
        let chartData = [];
        for(let j=0; j<data.length; j++) {
          chartData.push({x: new Date(data[j].timestamp), y: data[j].value[tags[i]]});
          if(maxValue < data[j].value[tags[i]]) {
            maxValue = data[j].value[tags[i]];
          }
        }
        chartDataArr.push({
          tag: tags[i] == 'load_power_W' ? '방전전력' : '충전전력', 
          data: chartData, 
          color: tags[i] == 'load_power_W' ? 'blue' : 'red'});
      }
      return { chartData: chartDataArr, maxValue: maxValue };
    },
    getNextColor() {
      let borderColors = [
        '#2196F3', '#4CAF50', '#FF9800', '#795548', '#607D8B', '#673AB7'
      ];
      let x = borderColors[this.colorIdx++];
      this.colorIdx = this.colorIdx % borderColors.length;
      return x;
    },
    async getWeatherData(stn, to, from) {
      if(stn == undefined) {
        this.$notify({
          type: 'error',
          group: 'foo',
          text: "강우량계 장치의 stn값을 확인해주십시오.",
          duration: 60 * 1000
        });
        return [];
      }

      let queryStr = `from=${from}&to=${to}`;
      let ret = await axios({ method: 'get', url: `/api/weather/${stn}?${queryStr}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });

      if(ret.data.error != undefined) {
        this.$notify({
          type: 'error',
          group: 'foo',
          text: ret.data.error,
          duration: 60 * 1000
        });
      }
      return ret.data.data;
    },
    async doSearch() {
      this.loading = true;
      try {
        this.emtpyHint = "";
        this.colorIdx = 0;
        let from = this.fromTime.toISOString();
        let to = this.toTime.toISOString();
        let devices = await this.getDevices();
        this.chartList = [];

        if(devices.solarDev != null || devices.rainDev != null || devices.flowDev.length > 0) {
          if(devices.flowDev != null) {
            for(let i=0; i<devices.flowDev.length; i++) {
              let flowChartData = await this.getChartData(devices.flowDev[i].devId, to, from, "m^3/hour");
              this.chartList.push({
                chartId: `${devices.flowDev[i].devId}-${i}`,
                subChartId: `${devices.flowDev[i].devId}-${i}-sub`,
                title: `${devices.flowDev[i].devType}`,
                data: flowChartData.chartData,
                borderColor: this.getNextColor(),
                maxValue: flowChartData.maxValue,
                yAxisName: "m³/hour"
              });
            }
          }
          if(devices.rainDev != null) {
            let rainChartData = await this.getChartData(devices.rainDev.devId, to, from, "mm/hour");
            this.chartList.push({
              chartId: `${devices.rainDev.devId}`,
              subChartId: `${devices.rainDev.devId}-sub`,
              title: `${devices.rainDev.devType}`,
              data: rainChartData.chartData,
              borderColor: this.getNextColor(),
              maxValue: rainChartData.maxValue,
              yAxisName: "mm/hour"
            });
            if(!isNaN(devices.stn)) {
              let wthData = await this.getWeatherData(devices.stn, to, from);
              let wthChartData = [];
              let maxVal = 0;
              for(let i=wthData.length-1; i>=0; i--) {
                let rn = parseFloat(wthData[i].rn);
                wthChartData.push({x: new Date(wthData[i].tm), y:rn});
                if(maxVal < rn) {
                  maxVal = rn;
                }
              }
              this.chartList.push({
                chartId: `${devices.rainDev.devId}-real`,
                subChartId: `${devices.rainDev.devId}-real-sub`,
                title: `기상청 강우량`,
                data: wthChartData,
                borderColor: this.getNextColor(),
                maxValue: maxVal,
                yAxisName: "mm/hour"
              });
            } else {
              this.$notify({
                type: 'info',
                group: 'foo',
                text: `강우량계의 설명 정보에 stn값이 존재하지 않아 기상청 강수량 정보를 가져올 수 없습니다.`,
                duration: 60 * 1000
              });
            }
          }
          if(devices.solarDev != null) {
            let solarChartData = await this.getChartMultiData(devices.solarDev.devId, to, from, "pv_array_input_power_W", "load_power_W");
            this.chartList.push({
              chartId: `${devices.solarDev.devId}`,
              subChartId: `${devices.solarDev.devId}-sub`,
              title: `태양광`,
              data: solarChartData.chartData,
              borderColor: this.getNextColor(),
              maxValue: solarChartData.maxValue,
              yAxisName: "W",
              multiple: true,
            });
            let battChartData = await this.getChartData(devices.solarDev.devId, to, from, "battery_soc_%");
            this.chartList.push({
              chartId: `${devices.solarDev.devId}-batt`,
              subChartId: `${devices.solarDev.devId}-batt-sub`,
              title: `배터리`,
              data: battChartData.chartData,
              borderColor: this.getNextColor(),
              maxValue: 100,
              yAxisName: "%",
              fixedMax: true
            });
          }
        } else {
          this.emtpyHint = `${this.selectedGroup.groupName}: 등록된 장치가 없습니다` 
        }
        this.forceRerender();
      } catch(e) {
        console.log(e);
        this.$notify({
            type: 'error',
            group: 'foo',
            text: `검색중 에러가 발생했습니다. 다시 시도해주십시오.`,
            duration: 60 * 1000
          });
      }
      this.loading = false;
    },
    async csvDownload(from, to) {
      console.log('csvdownload');
      this.csvExportLoading = true;
 
      from = new Date(from);
      to = new Date(to);
      let fromStr = from.toISOString();
      let toStr = to.toISOString();
      try {
        let devices = await this.getDevices();
        let dataList = [];
        if(devices.solarDev != null || devices.rainDev != null || devices.flowDev.length > 0) {
          if(devices.flowDev != null) {
            for(let i=0; i<devices.flowDev.length; i++) {
              let flowChartData = await this.getChartData(devices.flowDev[i].devId, toStr, fromStr, "m^3/hour");
              dataList.push({
                fileName: this.getFileName(`${devices.flowDev[i].devType}`, from, to),
                data: flowChartData.chartData
              });
            }
          }
          if(devices.rainDev != null) {
            let rainChartData = await this.getChartData(devices.rainDev.devId, toStr, fromStr, "mm/hour");
            dataList.push({
              fileName: this.getFileName(`${devices.rainDev.devType}`, from, to),
              data: rainChartData.chartData
            });
            if(!isNaN(devices.stn)) {
              let wthData = await this.getWeatherData(devices.stn, toStr, fromStr);
              let wthChartData = [];
              for(let i=wthData.length-1; i>=0; i--) {
                let rn = parseFloat(wthData[i].rn);
                wthChartData.push({x: new Date(wthData[i].tm), y:rn});
              }
              dataList.push({
                fileName: this.getFileName(`기상청강우량`, from, to),
                data: wthChartData
              });
            }
          }
          if(devices.solarDev != null) {
            let solarChartData = await this.getChartMultiData(devices.solarDev.devId, toStr, fromStr, "pv_array_input_power_W", "load_power_W");
            for(let i=0; i<solarChartData.chartData.length; i++) {
              dataList.push({
                fileName: this.getFileName(`${devices.solarDev.devType}-${solarChartData.chartData[i].tag}`, from, to),
                data: solarChartData.chartData[i].data
              });
            }
            let battChartData = await this.getChartData(devices.solarDev.devId, toStr, fromStr, "battery_soc_%");
            dataList.push({
              fileName: this.getFileName(`${devices.solarDev.devType}-battery`, from, to),
              data: battChartData.chartData
            });
          }
        }
        let exportType = exportFromJSON.types.csv;
        let fileCnt = 0;
        for(let i=0; i<dataList.length; i++) {
          let orgData = dataList[i].data;
          if(orgData.length == 0) {
            continue;
          }
          let dataArr = [];
          for(let j=0; j<orgData.length; j++) {
            dataArr.push({time: new Date(orgData[j]['x']).toLocaleString(), value: orgData[j]['y']});
          }
          let fileName = dataList[i].fileName;
          exportFromJSON({data: dataArr, fileName: fileName, exportType: exportType, withBOM: true});
          fileCnt++;
        }
        this.csvExportSuccess = `총 ${fileCnt}개의 CSV파일이 다운로드 완료됐습니다.`;
        if(dataList.length == 0) {
          this.csvExportErr = "데이터가 존재하지 않습니다.";
        } else {
          this.csvExportErr = '';
        }
      } catch(e) {
        console.log(e);
        this.csvExportErr = e.toString();
      }
      this.csvExportLoading = false;
    },
    getFileName(devType, from, to) {
      let grpName = this.$store.state.groupName == 'admin' ? this.selectedGroup.groupName : this.$store.state.groupName;
      return `${from.YYYYMMDDhhmm()}-${to.YYYYMMDDhhmm()}-${devType}-${grpName}`;
    },
  },
  data() {
    let toTime = new Date();
    toTime.setHours(23, 59);
    let fromTime = new Date();
    fromTime.setDate(toTime.getDate() - 7);
    fromTime.setHours(0);
    fromTime.setMinutes(0);
    return {
      emtpyHint: "위치를 선택하고 검색해주세요",
      loading: false,
      redrawKey: 0,
      fromRangeErr: "", toRangeErr: "",
      fromTime: fromTime, toTime: toTime,
      colorIdx: 0,
      datasets: [],
      chartList: [],
      selectedGroup: {},
      groups: [],
      chartStyle: {
        titleColor: 'rgba(21,101,192, 1.0)',
      },
      csvExportLoading: false,
      csvExportErr: '',
      csvExportSuccess: ''
    }
  }
}
</script>