<template>
  <v-sheet elevation="1" outlined>
    <v-sheet class="pa-2" elevation="1" outlined>
      <v-sheet class="pa-1" >
        <h3>장비 운용 현황</h3>
      </v-sheet>
      <v-divider class="ma-1"></v-divider>
      <v-row>
        <v-col cols="4">
          <v-sheet class="ma-2 text-center">
            <h1 class="blue--text text--darken-3">{{normalList.length}}</h1>
            <h4 class="blue--text text--darken-1">정상 동작</h4>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-sheet class="ma-2 text-center">
            <h1 class="grey--text">{{noUplinkList.length}}</h1>
            <h4 class="grey--text">24시간 이상 미통신</h4>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-sheet class="ma-2 text-center">
            <h1 class="red--text text--lighten-1">{{noKeepaliveList.length}}</h1>
            <h4 class="red--text text--lighten-1">Keep-alive 미수신</h4>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>

export default {
  name: 'AdminGatewayPanel',
  components: {
  },
  props: {
    allGateways: Array,
  },
  watch: {
    allGateways() {
      this.updatePanel();
    }
  },
  mounted() {
    this.updatePanel();
  },
  methods: {
    updatePanel() {
      let _normalList = [];
      let _noUplinkList = [];
      let _noKeepaliveList = [];
      for(let i=0; i<this.allGateways.length; i++) {
        if(this.allGateways[i].gwType == '가상장치') {
          continue;
        }
        let curDate = new Date();
        let recentAct = new Date(this.allGateways[i].gwRecentAct);
        let keepalive = new Date(this.allGateways[i].gwKeepalive);
        if(curDate.getTime() - keepalive.getTime() > (2*60*1000)) {
          _noKeepaliveList.push(this.allGateways[i]);
        } else if(this.allGateways[i].gwRecentAct == undefined 
          || curDate.getTime() - recentAct.getTime() > (24*60*60*1000)) {
          _noUplinkList.push(this.allGateways[i]);
        } else {
          _normalList.push(this.allGateways[i]);
        }
      }
      this.normalList = _normalList;
      this.noUplinkList = _noUplinkList;
      this.noKeepaliveList = _noKeepaliveList;
    }
  },
  data() {
    return {
      normalList: [],
      noUplinkList: [],
      noKeepaliveList: []
    }
  },
}
</script>