<template>
  <div style="height:100%;">
    <v-btn class="chart_y_axis_setting_button" x-small rounded depressed @click="openEditYAxisDlg">
      Y축 설정
      <v-icon x-small>
        mdi-pencil
      </v-icon>
    </v-btn>
    <canvas :id="chartId"></canvas>

    <v-dialog v-model="dlgEditYMax" width="600" :retain-focus="false">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-sheet class="px-3 py-2">
          <v-list>
            <v-list-item two-line>
              <v-list-item-content style="border-left: 6px solid #2c3e50;padding-left:10px;margin-bottom:5px;">
                <v-list-item-title>Y축 최대/최소값 설정</v-list-item-title>
                <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
            <v-list-item>
              <v-row class="ma-2">
                <v-col cols="6">
                  <v-text-field v-model="yMaxInput" type="number" label="Y축 최대값" :suffix="yUnit" :rules="yMaxRules"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="yMinInput" type="number" label="Y축 최소값" :suffix="yUnit" :rules="yMinRules"></v-text-field>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="3" class="pr-3">
                  <v-btn class="" Depressed block @click="closeEditYAxisDlg" elevation="0">취소</v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn color="primary" :disabled="!formValid" Depressed block @click="applyYAxis" elevation="0">저장</v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ko } from 'date-fns/locale';

Chart.register(...registerables);
Chart.defaults.font.family = 'NanumSquareRoundB';

export default {
  name: 'LineChart',
  props: {
    chartId: String,
    title: String,
    dataArr: Array,
    titleColor: String,
    borderColor: String,
    fillColor: String,
    yAxisMin: Number,
    yAxisMax: Number,
    yUnit: String
  },
  mounted() {
    const ctx = document.getElementById(this.chartId).getContext('2d');
    this.yMax = this.yAxisMax;
    this.yMin = this.yAxisMin;
    this.chartObj = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: this.title,
          data: this.dataArr,
          fill: {
            target: 'origin',
            above: this.fillColor
          },
          borderColor: this.borderColor
        }]
      },
      options: {
        scales: {
          y: {
            min: this.yMin, max:  this.yMax,
          },
          x: {
            type: "time",
            ticks: {
              source: 'data'
            },
            adapters: {
              date: {
                locale: ko
              }
            },
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            align: 'center',
            display: true,
            position: 'top',
            text: this.title,
            color: this.titleColor,
            font: {
              weight: 'bold',
              size: this.titleFontSize(),
              lineHeight: 2.0,
              family: 'NanumSquareRoundB'
            }
          },
          tooltip: {
            titleFont: {
              size: 20
            },
            bodyFont: {
              size: 20
            }
          }
        },
        elements: {
          point: {
            radius: this.pointSize(),
            hoverRadius: 7,
            borderWidth: 2,
            backgroundColor: 'rgba(255, 255, 255, 1.0)'
          },
          line: {
            tension: 0.2,
            borderWidth: 2
          }
        },
        animation: false,
        maintainAspectRatio: false,
        responsive: true
      }
    });
  },
  methods: {
    titleFontSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '20em' : '12em';
    },
    pointSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 2 : 1;
    },
    openEditYAxisDlg() {
      this.yMaxInput = `${this.yMax}`;
      this.yMinInput = `${this.yMin}`;
      this.dlgEditYMax = true;
    },
    applyYAxis() {
      this.$emit('changeYAxis', this.yMaxInput, this.yMinInput);
      this.dlgEditYMax = false;
    },
    closeEditYAxisDlg() {
      this.dlgEditYMax = false;
    },
    
  },
  data() {
    return {
      chartObj: null,
      dlgEditYMax: false,
      yMaxInput: '100',
      yMinInput: '0',
      yMax: 100,
      yMin: 0,
      formValid: true,
      yMaxRules: [
        v => !!v || '최대값을 입력해주세요',
        v => !isNaN(v) || '잘못된 값입니다',
        v => v > this.yMinInput || '최소값보다 커야 합니다'
      ],
      yMinRules: [
        v => !!v || '최소값을 입력해주세요',
        v => !isNaN(v) || '잘못된 값입니다',
        v => v < this.yMaxInput || '최대값보다 작아야 합니다'
      ],
    }
  }
}
</script>

<style scoped>
.chart_y_axis_setting_button {
  position:absolute;
  margin-left:-4px;
  margin-top:15px;
}
</style>