<style scoped>
.leaflet-popup-content-wrapper {
  background: rgba(255,255,255,0.8);
  color: #2c3e50;
  font-size: 10px;
  line-height: 24px;
  border-radius: 4px;
}
</style>
<template>
  <l-map style="height:100%;z-index:0;" :bounds="bounds">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker
      v-for="{name, latlng, color, devId, devDesc, devType, index} in markers" :key="index"
      ref="markers"
      :icon="getMarkerIcon(name)"
      :lat-lng="latlng"
      :radius="6"
      :fillColor="color"
      :fillOpacity="0."
      :color="color" 
      @click="openEdgeEyeViewer(devId, devType, devDesc)">
        <l-tooltip>
          {{name}}
        </l-tooltip>
    </l-marker>
    <v-dialog v-model="dlgPic" width="unset" :retain-focus="false" >
      <EdgeEyeViewer :isAdmin="isAdmin" :devId="selectedDevId" :devDesc="selectedDevDesc" :groupId="groupId" 
        v-on:close="dlgPic = false"></EdgeEyeViewer>
    </v-dialog>
  </l-map>
</template>

<script>
import { icon, latLng, latLngBounds } from 'leaflet';
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import EdgeEyeViewer from '../Common/EdgeEyeViewer.vue';

export default {
  name: 'MapView',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    EdgeEyeViewer
  },
  props: {
    mapParam: Object,
    groupId: String,
    isAdmin: Boolean
  },
  watch: {
    mapParam() {
      this.updateMarkers();
    },
  },
  mounted() {
  },
  methods: {
    openEdgeEyeViewer(devId, devType, devDesc) {
      if(devType == 'EdgeEye') {
        this.selectedDevDesc = devDesc;
        this.selectedDevId = devId;
        this.dlgPic = true;
      }
    },
    updateMarkers() {
      let gateways = this.mapParam.gateways;
      let devices = this.mapParam.devices;
      let gatewayMarkers = [];
      let deviceMarkers = [];

      for(let i=0; i<gateways.length; i++) {
        if(gateways[i].gwLat != undefined && gateways[i].gwLng != undefined
          && gateways[i].gwType != '가상장치') {
          gatewayMarkers.push({
            name: `${gateways[i].gwType} (${gateways[i].gwId})`, 
            latlng: latLng(gateways[i].gwLat, gateways[i].gwLng),
            color: 'orange'
          });
        }
      }

      for(let i=0; i<devices.length; i++) {
        if(devices[i].devGps != undefined && (devices[i].devType == '강우량계' 
          || devices[i].devType == 'EdgeEye' || devices[i].devType.startsWith('유량계'))) {
          try {
            deviceMarkers.push({
              name: `${devices[i].devType} (${devices[i].devDesc})`, 
              latlng: latLng(devices[i].devGps[0], devices[i].devGps[1]),
              color: 'blue',
              devId: devices[i].devId,
              devType: devices[i].devType,
              devDesc: devices[i].devDesc,
            });
          } catch(e) {
            console.log(e);
          }
        }
      }

      let bounds = [];
      let markers = [...gatewayMarkers, ...deviceMarkers];
      for(let i=0; i<markers.length; i++) {
        bounds.push([markers[i].latlng.lat, markers[i].latlng.lng]);
        markers.open
      }
      if(bounds.length > 0) {
        this.bounds = latLngBounds(bounds).pad(0.1);
      }
      this.markers = markers;
    },
    getMarkerIcon(name) {
      if(name.startsWith('EdgeEye')) {
        return this.camIcon;
      } else if(name.startsWith('유량계')) {
        return this.waterIcon;
      } else if(name.startsWith('강우량계')) {
        return this.rainIcon;
      } else {
        return this.routerIcon;
      }
    } 
  },
  data() {
    return {
      dlgPic: false,
      selectedDevDesc: '',
      selectedDevId: '',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      bounds: null,
      camIcon: icon({iconUrl: require('@/assets/images/cctv-camera-64.png'), iconSize: [32, 37], iconAnchor: [16, 37]}),
      routerIcon: icon({iconUrl: require('@/assets/images/wireless-router-64.png'),iconSize: [32, 37],iconAnchor: [16, 37]}),
      rainIcon: icon({iconUrl: require('@/assets/images/rainfall-64.png'),iconSize: [32, 37],iconAnchor: [16, 37]}),
      waterIcon: icon({iconUrl: require('@/assets/images/water-level-64.png'),iconSize: [32, 37],iconAnchor: [16, 37]}),
    }
  },
}
</script>