<template>
  <v-sheet class="px-3 pb-2 mb-2" width="100%">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="8"><v-img max-width="450" src="/triangular_weir.png"></v-img></v-col>
      <v-col cols="3">
        <v-select v-model="var_D" :items="values" label="D" :rules="var_D_rules"
          style="margin-top:156px;margin-left:-40px;margin-right:-40px;" outlined dense></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-select v-model="var_B" :items="values" label="B" :rules="var_B_rules"
          style="margin-top:-30px;" outlined dense></v-select>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="3" style="padding:0px;">
        <v-text-field v-model="var_dist_from_ground" label="센서 높이" :rules="var_dist_rules"
          outlined dense></v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TriangleWeir',
  components: {
  },
  props: {
    ppParam: Object
  },
  watch: {
  },
  mounted() {
    this.parseParam();
  },
  methods: {
    parseParam() {
      if(this.ppParam.type == 'triangular') {
        this.var_D = this.ppParam.D;
        this.var_B = this.ppParam.B;
        this.var_dist_from_ground = this.ppParam.dist_from_ground;
      }
    }
  },
  data() {
    return {
      values: ["1in", "2in", "3in", "6in", "9in", "1ft", "1ft 6in", "2ft", "2ft 6in", "3ft", "4ft", "5ft", "6ft",
               "7ft", "8ft", "10ft", "12ft", "15ft", "20ft", "25ft", "30ft", "40ft", "50ft"],
      var_B: '',
      var_D: '',
      var_dist_from_ground: '',
      var_dist_rules: [
        v => !!v || '값을 입력해주세요',
        v => !v.toString().includes(' ') || '잘못된 값입니다',
        v => !isNaN(v) || '잘못된 값입니다'
      ],
      var_D_rules: [ v => !!v || '값을 선택해주세요',],
      var_B_rules: [ v => !!v || '값을 선택해주세요',]
    };
  },
}
</script>