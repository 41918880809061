
<style>
.error-on-right .v-text-field__details  {
  margin-top: -30px;
  margin-right: -150px;
  margin-left: 150px;
}
</style>
<template>
  <v-sheet class="px-3 pb-2 mb-2" width="100%">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="11" style="padding:0px;padding-top:20px;">
        <v-img max-width="450" src="/parshall_flume_top.png"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="3" style="padding:0px;margin-left:-10px;margin-right:-10px;padding-bottom:5px;">
        <v-select class="error-on-right" v-model="var_C" :items="values" label="C" :rules="var_C_rules" outlined dense></v-select>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="11" style="padding:0px;padding-bottom:20px;">
        <v-img max-width="450" src="/parshall_flume_bottom.png"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="3" style="padding:0px;">
        <v-text-field v-model="var_dist_from_ground" label="센서 높이" :rules="var_dist_rules" outlined dense></v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ParshallFlume',
  components: {
  },
  props: {
    ppParam: Object
  },
  watch: {
  },
  mounted() {
    this.parseParam();
  },
  methods: {
    parseParam() {
      if(this.ppParam.type == 'parshall') {
        this.var_C = this.ppParam.C;
        this.var_dist_from_ground = this.ppParam.dist_from_ground;
      }
    }
  },
  data() {
    return {
      values: ["1in", "2in", "3in", "6in", "9in", "1ft", "1ft 6in", "2ft", "2ft 6in", "3ft", "4ft", "5ft", "6ft",
               "7ft", "8ft", "10ft", "12ft", "15ft", "20ft", "25ft", "30ft", "40ft", "50ft"],
      var_C: '',
      var_dist_from_ground: '',
      var_dist_rules: [
        v => !!v || '값을 입력해주세요',
        v => !v.toString().includes(' ') || '잘못된 값입니다',
        v => !isNaN(v) || '잘못된 값입니다'
      ],
      var_C_rules: [ v => !!v || '값을 선택해주세요',],
    };
  },
}
</script>