<template>
  <v-row :class="{'hw-full': $vuetify.breakpoint.mdAndUp}" no-gutters dense>
    <v-col :class="{'ch-full': $vuetify.breakpoint.mdAndUp}" :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12">
      <v-row :class="{'h-full': $vuetify.breakpoint.mdAndUp}" no-gutters dense>
        <v-col class="pa-1" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" 
          :style="[$vuetify.breakpoint.mdAndUp ? {'height':'50%'} : {'height':'300px'}]">
          <MapView :mapParam="mapParam" :groupId="$route.query.groupId" :isAdmin="this.$store.state.groupName=='admin'" />
        </v-col>
        <v-col class="pa-1" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" 
          :style="[$vuetify.breakpoint.mdAndUp ? {'height':'50%'} : {'height':'300px'}]">
          <RainChart :rainMeter="rainMeter" :groupId="this.$route.query.groupId"/>
        </v-col>
        <v-col v-if="sensors.length > 0" cols="12" 
          :style="[$vuetify.breakpoint.mdAndUp ? {'height':'50%'} : {'height':'1040px'}]">
          <v-tabs v-model="tab" class="pa-1">
            <v-tab v-for="(sensor, index) in sensors" :key="index" :sensor="sensor">
              {{ sensor.devType }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" :style="[$vuetify.breakpoint.mdAndUp ? {'height':'100%'} : {}]">
            <v-tab-item class="ma-1" :style="[$vuetify.breakpoint.mdAndUp ? {'height':'100%'} : {'height':'1000px'}]"
              v-for="(sensor, index) in sensors" :key="index" :sensor="sensor">
              <SensorChart class="tab-full" :sensor="sensor" :groupId="$route.query.groupId"/>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pa-1" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12"
      :style="[$vuetify.breakpoint.mdAndUp ? {'max-height':'calc(100vh - 110px)','overflow-y':'scroll'} : {}]">
      <SubPanel :isAdmin="this.$store.state.groupName=='admin'" :groupName="getGroupName()" :groupId="$route.query.groupId"
        :redrawFlag="redrawFlag" :keepalive="keepalive"/>
      <EdgeEyePanel :isAdmin="this.$store.state.groupName=='admin'" :groupName="getGroupName()" :groupId="$route.query.groupId"
        :redrawFlag="redrawFlag"/>
    </v-col>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import axios from 'axios';
import RainChart from './Dashboard/RainChart.vue';
import SensorChart from './Dashboard/SensorChart.vue';
import SubPanel from './Common/SubPanel.vue';
import EdgeEyePanel from './Common/EdgeEyePanel.vue';
import MapView from './Dashboard/MapView.vue';
import Utils from '../js/utils';

export default {
  name: 'DashbaordView',
  components: {
    RainChart, SensorChart, MapView, SubPanel, EdgeEyePanel
  },
  mounted() {
    this.loadDeviceList(true);

    setInterval(() => {
      this.loadDeviceList(false);
    }, 10*1000);
  },
  methods: {
    getGroupName() {
      return this.$route.query.groupName != undefined ? this.$route.query.groupName : this.$store.state.groupName;
    },
    async loadDeviceList(loading) {
      console.log('loadDeviceList');
      try {
        this.loading = loading;
        let qryGroupId = this.$route.query.groupId;
        let ret = await axios({ method: 'get', url: `/api/devices${qryGroupId != undefined ? '/'+qryGroupId : ''}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let devices = ret.data.devices;
        if(devices != null || devices.length > 0) {
          let sensors = [];
          for(let i=0; i<devices.length; i++) {
            if(devices[i].devType == '강우량계') {
              this.rainMeter = devices[i];
            } else if(/^유량계-.*/.test(devices[i].devType)) {
              sensors.push({
                devId: devices[i].devId, 
                devType: devices[i].devType,
                devDesc: devices[i].devDesc 
              });
            }
          }
          this.sensors = Utils.devSort(sensors);
        }
        ret = await axios({ method: 'get', url: `/api/gateways${qryGroupId != undefined ? '/'+qryGroupId : ''}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let gateways = ret.data.gateways;
        if(gateways.length > 0) {
          this.keepalive = new Date(gateways[0].gwKeepalive).toLocaleString();
          this.redrawFlag = !this.redrawFlag;
        }
        this.mapParam = {devices: devices, gateways: gateways};
      } catch (error) {
        console.error(error.response);
      }
      this.loading = false;
    }
  },
  data: function() {
    return {
      loading: false,
      redrawFlag: false,
      keepalive: "",
      rainMeter: {
        devType: "--",
        devId: "--"
      },
      sensors: [],
      mapParam: {
        devices: [],
        gateways: []
      },
      tab: null,
      tabItems: []
    };
  },
}
</script>

<style>
.ch-full {
  height: calc(100% - 94px);
}
.tab-full {
  height: calc(100% - 30px);
}
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.hw-full {
  height: 100%;
  width: 100%;
}
.no-padding {
  padding: 0;
}
</style>
