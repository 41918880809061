<template>
  <v-app-bar v-if="visible" app color="primary" elevation="0" dark dense>
    <SideMenu v-if="headInfo.sideMenu" />
    <div class="d-flex align-center">
      <v-icon class="mr-5" v-if="headInfo.icon != ''" large>{{headInfo.icon}}</v-icon>
      <div class="text-h5 font-weight-bold">{{headInfo.title}}<span class="text-h6 ml-2">{{ headInfo.subtitle }}</span></div>
    </div>
  </v-app-bar>
</template>

<script>
import SideMenu from './SideMenu.vue';

export default {
  name: 'HeaderVue',
  components: {
    SideMenu
  },
  computed: {
    visible() {
      return this.$store.state.isLoggedIn;
    }
  },
  watch: {
    $route(to, from) {
      let groupName = this.$store.state.groupName;
      if(groupName == 'admin' && ['/', '/admin', ''].includes(this.$router.currentRoute.path)) {
        this.headInfo = { title: '관리자 모드', icon: '', sideMenu: true};
      } else if(groupName == 'admin' && ['/edge-eye-grid'].includes(this.$router.currentRoute.path)) {
        this.headInfo = {title: 'EdgeEye 모아보기', icon: '', sideMenu: true};
      } else if(['/', '/dashboard', ''].includes(this.$router.currentRoute.path)) {
        this.headInfo = {title: this.$route.query.groupName != undefined ? this.$route.query.groupName : groupName, icon: '', sideMenu: true};
      } else if(['/search-chart'].includes(this.$router.currentRoute.path)) {
        this.headInfo = {title: '검색 및 내보내기: 라인 차트', subtitle: `${groupName == 'admin' ? "(관리자 모드)" : ''}`, 
          icon: '', sideMenu: true};
      } else if(['/search-table'].includes(this.$router.currentRoute.path)) {
        this.headInfo = {title: '검색 및 내보내기: 데이타 테이블', subtitle: `${groupName == 'admin' ? "(관리자 모드)" : ''}`, 
          icon: '', sideMenu: true};
      }
    }
  },
  data: () => ({
    headInfo: {
      title: "--", icon: "mdi-map", sideMenu: true
    }
  }),
};
</script>