<template>
  <div style="height:100%;">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {ko} from 'date-fns/locale';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as Hammer from 'hammerjs';

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.defaults.font.family = 'NanumSquareRoundB';

export default {
  name: 'SimpleMultiChart',
  props: {
    chartId: String,
    dataArr: Array,
    title: String,
    yAxisName: String,
    borderColor: String,
    maxValue: Number,
    fixedMax: Boolean
  },
  mounted() {
    const ctx = document.getElementById(this.chartId).getContext('2d');
    
    let max = 0;
    let min = 0;
    for(let i=0; i<this.dataArr.length; i++) {
      if(this.dataArr[i].data.length > 0) {
        let _max = new Date(this.dataArr[i].data[0].x).getTime();
        let _min = new Date(this.dataArr[i].data[this.dataArr[i].data.length-1].x).getTime();
        if(_max > max) {
          max = _max;
        }
        if(min == 0 || _min < min) {
          min = _min;
        }
      }
    }
    if(this.fixedMax) {
      this.yMax = this.maxValue;
    } else {
      this.yMax = this.dataArr.length > 0 ? Math.ceil(this.maxValue * 1.5) : 100;
    }
    this.yMin = 0;

    let scaleOptions = {
      x: {type: "time", ticks: {source: 'auto', autoSkip: true, maxTicksLimit: 7},
        time: {minUnit: 'hour', displayFormats: {day: 'M/d', hour: 'M/d HH:MM'}},
        adapters: {date: {locale: ko}},},
      y: {min: this.yMin, max: this.yMax},
    };
    let elementOptions = {
      point: {pointStyle: true, radius: 2, borderWidth: 0, backgroundColor: 'rgba(255,255,255,0)', hitRadius: 5},
      line: {tension: 0.2, borderWidth: 2}
    };
    let pluginOptions = {
      legend: {display: false},
      title: {align: 'center', display: true, position: 'top', text: max > 0 ? this.title : `${this.title}(데이타 없음)`,
        font: {weight: 'bold', size: '12em', lineHeight: 2.0, family: 'NanumSquareRoundB'}},
    }

    let _datasets = [];
    for(let i=0; i<this.dataArr.length; i++) {
      _datasets.push({label: this.dataArr[i].tag, data: this.dataArr[i].data, borderColor: this.dataArr[i].color});
    }
    this.chartObj = new Chart(ctx, {
      type: 'line',
      data: {datasets: _datasets},
      options: {
        scales: scaleOptions, elements: elementOptions, plugins: pluginOptions,
        animation: false, maintainAspectRatio: false, responsive: true
      },
    });
  },
  methods: {
  },
  data() {
    return {
      chartObj: null,
      yMax: 100,
      yMin: 0,
    }
  }
}
</script>

<style scoped>
</style>