<template>
  <v-container class="primary-bg" fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="10" align="center">
        <h1 class="mb-3 white--text">유량 및 강우량 모니터링 시스템</h1>
        <v-card class="px-14 py-14" style="width:500px;" outlined shaped>
            <v-text-field v-model="userId" label="아이디" prepend-icon="mdi-account" ></v-text-field>
            <v-text-field v-model="userPwd" label="비밀번호" type="password" prepend-icon="mdi-key"
              v-on:keyup.enter="login"></v-text-field>
          <v-card-actions class="px-0">
              <v-spacer></v-spacer>
              <v-btn style="width:100%;font-weight:bold;" color="primary" @click="login()">로그인</v-btn>
              <v-spacer></v-spacer>
          </v-card-actions>
          <v-alert v-if="error == 401" border="left" colored-border type="error" elevation="2" class="mt-4">
            아이디나 비밀번호가 올바르지 않습니다
          </v-alert>
          <v-alert v-if="error == 500" border="left" colored-border type="error" elevation="2" class="mt-4">
            서버오류: 관리자에 문의 바랍니다
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginView',
  methods: {
    async login() {
      try {
        const res = await axios({
          method: 'post',
          url: '/api/login',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          data: {
            id: this.userId,
            password: this.userPwd
          }
        });
        this.$store.commit('updateLoginInfo', {
          userName: this.userId,
          groupName: res.data.groupName,
          isLoggedIn: true
        });
        if(res.data.groupName == 'admin') {
          this.$router.push({path: '/admin'});
        } else {
          this.$router.push({path: '/dashboard'});
        }
      } catch (error) {
        console.error(error.response);
        this.error = error.response.status;
      }
    }
  },
  data: () => ({
    userId: '',
    userPwd: '',
    error: 200
  }),
}
</script>

<style>
.primary-bg {
  background-color: var(--v-primary-base);
}
</style>