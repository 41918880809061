import Vue from 'vue';
import Notifications from 'vue-notification'
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/router.js';
import store from './store/index';
import VueMoment from 'vue-moment';

Vue.config.productionTip = false;
import "@/assets/css/global.css";

Vue.use(Notifications);

new Vue({
  vuetify,
  router,
  store,
  VueMoment,
  render: h => h(App)
}).$mount('#app');
