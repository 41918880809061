<template>
  <div>
    <div class="d-flex">
      <div class="mr-auto" style="color:#6E6E6E">전체 데이터 갯수: {{ tableData.length }}</div>
      <div><v-text-field v-model="tableSearch" label="Search" dense prepend-inner-icon="mdi-magnify" 
        outlined hide-details></v-text-field></div>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      :items-per-page="10"
      :search="tableSearch"
      :footer-props="{itemsPerPageOptions: [5,10,15,20,50,100],'items-per-page-text':'페이지당 갯수'}"
      :show-select="false"
      no-data-text="표시할 데이터가 없습니다"
      no-results-text="검색된 데이터가 없습니다"
      style="border-width: 1px 1px 1px 1px;border-styles:solid;border-color:#E5E5E5;"
      dense
      :custom-filter="customFilter"
    >

      <template v-slot:[`item.timestamp`]="{ item }">
        {{ new Date(item.timestamp).toLocaleString() }}
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <template>
          <v-simple-table dense class="my-2" style="border: 1px solid #E5E5E5;width: fit-content;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="(trText, index) in getHeaders(item.value)" :key="index" 
                    style="text-align:center;background-color:#EEEEEE;">{{ trText }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(tdData, index) in getValues(item.value)" :key="index" 
                    style="text-align:center;">{{ tdData }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </template>
    </v-data-table> 
  </div>
</template>

<script>
let removeKeys = ['rssi', 'snr', 'fPort', 'fCnt', 'freq', 'mod', 'bw', 'adr', 'sf', 'cr', 'gateway', 'raw'];

export default {
  name: 'DataTable',
  props: {
    tableData: Array
  },
  components: {
  },
  mounted() {
  },
  methods: {
    getHeaders(obj) {
      let headers = [];
      for(let key in obj) {
        if(!removeKeys.includes(key) ) {
          headers.push(key);
        }
      }
      return headers;
    },
    getValues(obj) {
      let values = [];
      for(let key in obj) {
        if(!removeKeys.includes(key) ) {
          values.push(obj[key]);
        }
      }
      return values;
    },
    customFilter(value, search, item) {
      if(value.length < 2) {
        return false;
      }
      if(typeof value === 'object') {
        for(let key in value) {
          if(!removeKeys.includes(key) && value[key].toString().toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        }
      } else {
        return value.toLowerCase().includes(search.toLowerCase());
      }
      return false;
    }
  },
  data() {
    return {
      tableHeaders: [
        { text: '게이트웨이 ID', value: 'gateway_id', width: '15%', align: 'center', divider: true, filterable: true, sortable: true},
        { text: '장치 ID', value: 'node_id', width: '15%', align: 'center', divider: true, filterable: true, sortable: true},
        { text: '타임스탬프', value: 'timestamp', width: '15%', align: 'center', divider: true, filterable: false, sortable: true},
        { text: '데이터', value: 'value', width: '55%', align: 'center', divider: false, filterable: true, sortable: false},
      ],
      tableSearch: '',
    }
  }
}
</script>
<style scoped>
.v-data-table >>> tr:hover {
  background: transparent !important;
}
table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; }
</style>