<template>
  <v-footer color="primary" padless fixed>
    <v-row justify="center" no-gutters>
      <v-col class="primary py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>Coxlab Inc. / (주)강원건설</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterView',

  data: () => ({
  }),
}
</script>