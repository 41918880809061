<template>
  <v-sheet class="mb-2 pa-3" elevation="3" rounded>
    <div class="mb-2 pl-2 viewer-header">
      <div class="viewer-header-title d-flex justify-space-between align-start">
        <div>
          <div>ID: {{ edgeEye.devId }}</div>
          <div class="viewer-header-subtitle">{{ edgeEye.devDesc }}</div>
        </div>
        <v-icon color="green" @click="confirmDownload" large :disabled="this.imgCnt < 1">mdi-download-box</v-icon>
      </div>
    </div>
    <v-img class="mb-2" :src="getImageUrl()" v-if="this.edgeEye.images.length > 0"></v-img>
    <div v-if="this.edgeEye.images.length == 0" class="grey--text">저장된 이미지가 없습니다</div>
    <div class="d-flex justify-space-between align-center">
      <div><v-icon color="primary" @click="moveLeft" large :disabled="this.imgCnt-1==curIndex || this.imgCnt==0">mdi-chevron-left</v-icon></div>
      <div>
        <v-select v-model="tsSelect" :items="edgeEye.images" item-value="sense_time" @change="updateImgIndex" menuProps="auto" 
         return-object dense hide-details :disabled="this.edgeEye.images.length == 0">
        <template v-slot:selection="data">
          <span class="d-flex justify-center" style="width: 100%;">
          {{ new Date(data.item.sense_time).toLocaleString() }}
          </span>
        </template>
        <template v-slot:item="data">
          <span class="d-flex justify-center" style="width: 100%;">
          {{ new Date(data.item.sense_time).toLocaleString() }}
          </span>
        </template>
        </v-select>
      </div>
      <div><v-icon color="primary" @click="moveRight" large :disabled="curIndex==0 || this.imgCnt==0">mdi-chevron-right</v-icon></div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-sheet class="px-3 py-2">
        <v-list>
          <v-list-item two-line>
            <v-list-item-content style="border-left: 8px solid #2c3e50;padding-left:10px;">
              <v-list-item-title>Edge Eye 이미지 다운로드</v-list-item-title>
              <v-list-item-subtitle>ID: {{edgeEye.devId}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ edgeEye.devDesc }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            총 {{this.imgCnt}} 개의 이미지를 다운로드하겠습니까?
          </v-list-item>
          <v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-progress-linear v-model="downloadProgress"></v-progress-linear>
                <div class="d-flex justify-end" style="font-size:14px;">({{ this.downloadCnt }} / {{ this.imgCnt }})</div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
          <v-list-item>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="3" class="pr-3">
                <v-btn class="" depressed block @click="dialog = false">취소</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" depressed block @click="this.downloadImg" :loading="downloading">
                  다운로드
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-dialog>
  </v-sheet>
</template>
<script>
import Jszip from 'jszip';
import JszipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

Date.prototype.YYYYMMDDhhmmss = function() {
  var MM = this.getMonth() + 1; // getMonth() is zero-based
  var DD = this.getDate();
  var hh = this.getHours();
  var mm = this.getMinutes();
  var ss = this.getSeconds();

  return [this.getFullYear(), '-',
          (MM>9 ? '' : '0') + MM, '-',
          (DD>9 ? '' : '0') + DD, ' ',
          (hh>9 ? '' : '0') + hh, '_',
          (mm>9 ? '' : '0') + mm, '_',
          (ss>9 ? '' : '0') + ss,
         ].join('');
};

export default {
  name: 'EdgeEyeViewer',
  props: {
    edgeEye: Object
  },
  watch: {
    edgeEye() {
      if(this.edgeEye.images.length > 0) {
        this.imgCnt = this.edgeEye.images.length;
        this.curIndex = 0;
        this.tsSelect = this.edgeEye.images[this.curIndex];
      }
    }
  },
  components: {
  },
  mounted() {
    if(this.edgeEye.images.length > 0) {
      this.imgCnt = this.edgeEye.images.length;
      this.curIndex = 0;
      this.tsSelect = this.edgeEye.images[this.curIndex];
    }
  },
  methods: {
    getImageUrl() {
      let fileId =  this.tsSelect.file_id;
      let grpId = this.edgeEye.grpId;
      return `/api/data/image${grpId}/${fileId}`;
    },
    moveLeft() {
      if(this.curIndex < this.imgCnt-1) {
        this.curIndex += 1;
        this.tsSelect = this.edgeEye.images[this.curIndex];
      }
    },
    moveRight() {
      if(this.curIndex > 0) {
        this.curIndex -= 1;
        this.tsSelect = this.edgeEye.images[this.curIndex];
      }
    },
    updateImgIndex() {
      for(let i=0; i<this.edgeEye.images.length; i++) {
        if(this.edgeEye.images[i].sense_time == this.tsSelect.sense_time) {
          this.curIndex = i;
          break;
        }
      }
    },
    confirmDownload() {
      this.dialog = true;
    },
    downloadImg() {
      this.downloading = true;
      let urls = [];
      for(let i=0; i<this.edgeEye.images.length; i++) {
        urls.push({
          url: `/api/data/image${this.edgeEye.grpId}/${this.edgeEye.images[i].file_id}`,
          timestamp: new Date(this.edgeEye.images[i].sense_time).YYYYMMDDhhmmss()
        });
      }
      let zipFileName = `EdgeEye-${this.edgeEye.devId}.zip`;
      let zip = new Jszip();
      this.downloadCnt = 0;
      urls.forEach((url) => {
        JszipUtils.getBinaryContent(url.url, (err, data) => {
          if(err) {
            console.log(err);
          } else {
            zip.file(`${url.timestamp}.jpg`, data, {binary:true});
            this.downloadCnt++;
            this.downloadProgress = Math.ceil((this.downloadCnt / this.imgCnt) * 100);
            if (this.downloadCnt == urls.length) {
              zip.generateAsync({type:'blob'}).then((content) => {
                saveAs(content, zipFileName);
                this.downloading = false;
                setTimeout(() => {
                  this.dialog = false;
                  this.downloadProgress = 0;
                  this.downloadCnt = 0;
                }, 1000);
              });
            }
          }
        });
      });
    }
  },
  data() {
    return {
      curIndex: 0,
      imgCnt: 0,
      tsSelect: {},
      dialog: false,
      downloading: false,
      downloadCnt: 0,
      downloadProgress: 0
    };
  }
}
</script>
<style scoped>
.viewer-header {
  border-left: 8px solid #2c3e50
}
.viewer-header-title {
  font-size: 16px;
}
.viewer-header-subtitle {
  font-size: 13px;
  color: #8E8E8E;
}
.timestamp{
  font-size: 15px;
}

</style>