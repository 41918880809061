<template>
  <v-card class="pa-1" outlined>
    <v-row class="h-full" dense no-gutters>
      <v-col class="px-2" :cols="flowMeterCols" :style="[chartStyle]">
        <LineChart :key="redrawKey" :chartId="sensor.devId+'-flow'" title="유량 (m³/h)" :yAxisMin=flowYMin :yAxisMax=flowYMax
          :titleColor="flowStyle.titleColor" :borderColor="flowStyle.borderColor" :fillColor="flowStyle.fillColor"
          :dataArr="flowData" v-on:changeYAxis="handleChangeFlowYAxis" yUnit="m³/h" />
      </v-col>
      <v-col class="px-2" :cols="flowMeterCols" :style="[chartStyle]">
        <LineChart :key="redrawKey" :chartId="sensor.devId+'-temp'" title="평균 온도 (℃)" :yAxisMin=tempYMin :yAxisMax=tempYMax
          :titleColor="tempStyle.titleColor" :borderColor="tempStyle.borderColor" :fillColor="tempStyle.fillColor"
          :dataArr="tempData" v-on:changeYAxis="handleChangeTempYAxis" yUnit="℃"/>
      </v-col>
      <v-col class="px-2" :cols="flowMeterCols" :style="[chartStyle]">
        <LineChart :key="redrawKey" :chartId="sensor.devId+'-humid'" title="평균 습도 (%)" :yAxisMin=humidYMin :yAxisMax=humidYMax
          :titleColor="humidStyle.titleColor" :borderColor="humidStyle.borderColor" :fillColor="humidStyle.fillColor"
          :dataArr="humidData" v-on:changeYAxis="handleChangeHumidYAxis" yUnit="%"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from 'axios';
import LineChart from './LineChart.vue';

export default {
  name: 'DashbaordView',
  components: {
    LineChart,
  },
  props: {
    sensor: Object,
    groupId: String
  },
  computed: {
    chartStyle() { return this.$vuetify.breakpoint.mdAndUp ? {'height':'100%'} : {'height':'30%'}; },
    flowMeterCols() { return this.$vuetify.breakpoint.mdAndUp ? 4 : 12; },
  },
  mounted() {
    this.updateChart();
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods: {
    handleChangeFlowYAxis(yMax, yMin) {
      this.flowYMax = parseFloat(yMax);
      this.flowYMin = parseFloat(yMin);
      this.forceRerender();
    },
    handleChangeTempYAxis(yMax, yMin) {
      this.tempYMax = parseFloat(yMax);
      this.tempYMin = parseFloat(yMin);
      this.forceRerender();
    },
    handleChangeHumidYAxis(yMax, yMin) {
      this.humidYMax = parseFloat(yMax);
      this.humidYMin = parseFloat(yMin);
      this.forceRerender();
    },
    forceRerender() {
      this.redrawKey += 1;
    },
    async updateChart() {
      try {
        let curTime = new Date();
        let from = new Date(curTime.getTime() - (300 * 60 * 1000)).toISOString();
        let to = curTime.toISOString();
        let queryStr = `nid=${this.sensor.devId}&from=${from}&to=${to}&count=30`;
        const ret = await axios({ method: 'get', url: `/api/data${this.groupId != undefined ? '/'+this.groupId : ''}?${queryStr}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let data = ret.data.data;
        let flowData = [];
        let tempData = [];
        let humidData = [];
        for(let i=0; i<data.length; i++) {
          flowData.push({x: new Date(data[i].timestamp), y: data[i].value['m^3/hour']});
          tempData.push({x: new Date(data[i].timestamp), y: data[i].value.temperature});
          humidData.push({x: new Date(data[i].timestamp), y: data[i].value.humidity});
        }
        this.flowData = flowData;
        this.tempData = tempData;
        this.humidData = humidData;
        this.forceRerender();
      } catch(e) {
        console.log(e);
      }
    },
    startTimer() {
      this.timerId = setInterval(async () => {
        this.updateChart();
      }, 20 * 1000);
    }
  },
  data: function() {
    return {
      timerId: null,
      redrawKey: 0,
      flowData: [],
      tempData: [],
      humidData: [],
      flowYMax: 1500,
      flowYMin: 0,
      tempYMax: 50,
      tempYMin: -20,
      humidYMax: 100,
      humidYMin: 0,
      flowStyle: {
        titleColor: 'rgba(25, 118, 210, 1.0)',
        borderColor: 'rgba(25, 118, 210, 1.0)',
        fillColor: 'rgba(25, 118, 210, 0.2)',
      },
      tempStyle: {
        titleColor: 'rgba(67, 160, 71, 1.0)',
        borderColor: 'rgba(67, 160, 71, 1.0)',
        fillColor: 'rgba(67, 160, 71, 0.2)',
      },
      humidStyle: {
        titleColor: 'rgba(85, 138, 240, 1.0)',
        borderColor: 'rgba(85, 138, 240, 1.0)',
        fillColor: 'rgba(85, 138, 240, 0.2)',
      }
    };
  },
}
</script>

<style>
.h-full {
  height: 100%;
}
</style>
