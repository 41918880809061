<style scoped>
.width-max-full {
  max-width: 100%;
}
.row-pointer {
  cursor: pointer;
}
</style>
<template>
  <v-card class="mt-2" elevation="1" outlined>
    <v-list class="transparent" v-for="(sensor, index) in sensors" :key="index">
      <v-list-item two-line style="padding:4px;margin:0px;">
        <v-list-item-content style="padding:0px;margin:0px;">
          <v-list-item-title class="mb-2"><v-icon class="mb-2">mdi-video-wireless</v-icon> 
            {{sensor.devDesc.length > 0 ? sensor.devDesc : "EdgeEye"}}
          </v-list-item-title>
          <v-list-item-subtitle>
            <img class="row-pointer width-max-full" :src="getEdgeEyeUrl(sensor.devId)"
              @click="openPicture(sensor.devId, sensor.devDesc)" @error="imageError" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dlgPic" width="unset" :retain-focus="false" >
      <EdgeEyeViewer :isAdmin="isAdmin" :devId="selectedDevId" :devDesc="selectedDevDesc" :groupId="groupId" 
        v-on:close="dlgPic = false"></EdgeEyeViewer>
    </v-dialog>

    <v-list class="transparent" v-if="sensors.length < 1 && !loading">
      <v-list-item>
        <v-list-item-title>EdgeEye 장치가 없음</v-list-item-title>
      </v-list-item>
    </v-list>

    <loading :active.sync="loading" :opacity=0.0 :is-full-page="false" loader="bars" />
  </v-card>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EdgeEyeViewer from './EdgeEyeViewer.vue';

export default {
  name: 'EdgeEyePanel',
  components: {
    Loading,
    EdgeEyeViewer
  },
  props: {
    isAdmin: Boolean,
    groupId: String,
    groupName: String,
    redrawFlag: Boolean,
  },
  watch: {
    redrawFlag() {
      this.updatePanel();
    },
    groupId() {
      if(this.groupId != null) {
        this.loading = true;
      }
    }
  },
  mounted() {
  },
  methods: {
    async updatePanel() {
      console.log('update EdgeEyePanel');
      if(this.isAdmin && this.groupId == null) { return; }

      let _sensors = [];
      try {
        let ret = await axios({ method: 'get', url: `/api/devices${this.isAdmin ? '/'+this.groupId : ''}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let devices = ret.data.devices;
        if(devices != null && devices.length > 0) {
          for(let i=0; i<devices.length; i++) {
            if(devices[i].devType == 'EdgeEye') {
              _sensors.push(devices[i]);
            }
          }
        }
      } catch (error) {
        console.error(error.response);
      }
      this.sensors = _sensors;
      this.loading = false;
    },
    openPicture(devId, devDesc) {
      this.selectedDevDesc = devDesc;
      this.selectedDevId = devId;
      this.dlgPic = true;
    },
    getEdgeEyeUrl(devId) {
      return `/api/edge-eye/${devId.toUpperCase()}?key=${new Date().getTime()}`;
    },
    imageError: function(e) {
      e.target.src = '/image_not_available.png';
    }
  },
  data() {
    return {
      loading: false,
      sensors: [],
      dlgPic: false,
      selectedDevDesc: '',
      selectedDevId: ''
    }
  },
}
</script>