<template>
  <v-card style="height:100%;" outlined>
    <v-card class="fm-content ma-0 px-4" elevation="0">
      <LineChart :key="redrawKey" chartId="rainChart" title="강우량(mm/h)" :yAxisMin=yAxisMin :yAxisMax=yAxisMax
        :titleColor="chartStyle.titleColor" :borderColor="chartStyle.borderColor" :fillColor="chartStyle.fillColor"
        :dataArr="rainData" v-on:changeYAxis="handleChangeYAxis" yUnit="mm/h" />
    </v-card>
  </v-card>
</template>

<script>
import axios from 'axios';
import LineChart from './LineChart.vue';

export default {
  name: 'DashbaordView',
  components: {
    LineChart
  },
  props: {
    rainMeter: Object,
    groupId: String
  },
  watch: {
    rainMeter() {
      this.updateChart();
      this.startTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  mounted() {
  },
  methods: {
    forceRerender() {
      this.redrawKey += 1;
    },
    handleChangeYAxis(yMax, yMin) {
      this.yAxisMax = parseFloat(yMax);
      this.yAxisMin = parseFloat(yMin);
      this.forceRerender();
    },
    async updateChart() {
      console.log(this.groupId);
      try {
        let curTime = new Date();
        let from = new Date(curTime.getTime() - (300 * 60 * 1000)).toISOString();
        let to = curTime.toISOString();
        let queryStr = `nid=${this.rainMeter.devId}&from=${from}&to=${to}&count=30`;
        const ret = await axios({ 
          method: 'get', url: `/api/data${this.groupId != undefined ? '/'+this.groupId : ''}?${queryStr}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let data = ret.data.data;
        let chartData = [];
        for(let i=0; i<data.length; i++) {
          chartData.push({x: new Date(data[i].timestamp), y: data[i].value['mm/hour']});
        }
        this.rainData = chartData;
        this.forceRerender();
      } catch(e) {
        console.log(e);
      }
    },
    startTimer() {
      this.timerId = setInterval(async () => {
        this.updateChart();
      }, 20 * 1000);
    }
  },
  data: function() {
    return {
      timerId: null,
      redrawKey: 0,
      rainData: [],
      yAxisMax: 200,
      yAxisMin: 0,
      chartStyle: {
        titleColor: 'rgba(21,101,192, 1.0)',
        borderColor: 'rgba(79,195,247, 1.0)',
        fillColor: 'rgba(79,195,247, 0.2)',
      },
    };
  },
}
</script>

