<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list style="min-width:240px;">
      <v-list-item link @click="goToHome">
        <v-list-item-icon>
            <v-icon color="primary lighten-2">mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>메인으로</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="this.$store.state.groupName == 'admin'"></v-divider>
      <v-list-item link @click="goToEdgeEyeGrid" v-if="this.$store.state.groupName == 'admin'">
        <v-list-item-icon>
            <v-icon color="primary lighten-2">mdi-grid</v-icon>
        </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>EdgeEye 모아보기</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader><v-icon class="mr-2">mdi-magnify</v-icon>검색 및 내보내기</v-subheader>
      <v-list-item link @click="goToSearchChart">
        <v-list-item-icon>
            <v-icon color="primary lighten-2">mdi-chart-line</v-icon>
        </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>라인 차트</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="goToSearchTable">
        <v-list-item-icon>
            <v-icon color="primary lighten-2">mdi-table-large</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>데이타 테이블</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader><v-icon class="mr-2">mdi-account-box</v-icon>{{ this.$store.state.groupName }}</v-subheader>
      <v-list-item link @click="logout">
        <v-list-item-icon>
            <v-icon color="primary lighten-2">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>로그아웃</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SideMenu',
  methods: {
    // goToSearch() {
    //   const route = this.$router.resolve({path: '/search'});
    //   window.open(route.href, '_blank');
    // },
    goToHome() {
      if(this.$store.state.groupName == 'admin') {
        this.$router.push({path: '/admin'});
      } else {
        this.$router.push({path: '/dashboard'});
      }
    },
    goToEdgeEyeGrid() {
      if(this.$store.state.groupName == 'admin') {
        const route = this.$router.resolve({path: '/edge-eye-grid'});
        window.open(route.href, '_blank');
      }
    },
    goToSearchChart() {
      const route = this.$router.resolve({path: '/search-chart'});
      window.open(route.href, '_blank');
    },
    goToSearchTable() {
      const route = this.$router.resolve({path: '/search-table'});
      window.open(route.href, '_blank');
    },
    async logout() {
      try {
        const res = await axios({
          method: 'post',
          url: '/api/logout'
        });
        this.$store.commit('updateLoginInfo', {userName: "", isLoggedIn: false});
        this.$router.push({path: '/login'});
      } catch (error) {
        console.error(error.response);
      }
    }
  },
  data: () => ({
  }),
}
</script>